export const configuration = {
  apiKey: 'AIzaSyC488ejmN8QzzZRUU5B4R0tc_kQdzV6FYM',
  authDomain: 'auth.postmaker.io',
  databaseURL: 'https://postmaker-89742.firebaseio.com',
  projectId: 'postmaker-89742',
  storageBucket: 'postmaker-89742.appspot.com',
  messagingSenderId: '295493126422',
  appId: '1:295493126422:web:9cce6cdb14990722516837',
  measurementId: 'G-M2RZXRJWX1',
  clientId:
    '295493126422-q6rio4hhcf4kojv24alg55jq28deolok.apps.googleusercontent.com',
  scopes: ['email', 'profile'],
}
