export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('E:/Projects/PostMaker/postmaker/src/pages/index.vue'),
    /* no children */
  },
  {
    path: '/admin',
    /* internal name: '/admin' */
    /* no component */
    children: [
      {
        path: '',
        name: '/admin/',
        component: () => import('E:/Projects/PostMaker/postmaker/src/pages/admin/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/brands',
    /* internal name: '/brands' */
    /* no component */
    children: [
      {
        path: '',
        name: '/brands/',
        component: () => import('E:/Projects/PostMaker/postmaker/src/pages/brands/index.vue'),
        /* no children */
      },
      {
        path: ':brandId',
        name: '/brands/[brandId]',
        component: () => import('E:/Projects/PostMaker/postmaker/src/pages/brands/[brandId].vue'),
        /* no children */
      },
      {
        path: 'partials',
        /* internal name: '/brands/partials' */
        /* no component */
        children: [
          {
            path: 'BrandCampaignsList',
            name: '/brands/partials/BrandCampaignsList',
            component: () => import('E:/Projects/PostMaker/postmaker/src/pages/brands/partials/BrandCampaignsList.vue'),
            /* no children */
          },
          {
            path: 'BrandEditForm',
            name: '/brands/partials/BrandEditForm',
            component: () => import('E:/Projects/PostMaker/postmaker/src/pages/brands/partials/BrandEditForm.vue'),
            /* no children */
          },
          {
            path: 'BrandList',
            name: '/brands/partials/BrandList',
            component: () => import('E:/Projects/PostMaker/postmaker/src/pages/brands/partials/BrandList.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/campaigns',
    /* internal name: '/campaigns' */
    /* no component */
    children: [
      {
        path: '',
        name: '/campaigns/',
        component: () => import('E:/Projects/PostMaker/postmaker/src/pages/campaigns/index.vue'),
        /* no children */
      },
      {
        path: ':campaignId',
        name: '/campaigns/[campaignId]',
        component: () => import('E:/Projects/PostMaker/postmaker/src/pages/campaigns/[campaignId].vue'),
        /* no children */
      },
      {
        path: 'partials',
        /* internal name: '/campaigns/partials' */
        /* no component */
        children: [
          {
            path: 'CampaignEditForm',
            name: '/campaigns/partials/CampaignEditForm',
            component: () => import('E:/Projects/PostMaker/postmaker/src/pages/campaigns/partials/CampaignEditForm.vue'),
            /* no children */
          },
          {
            path: 'CampaignGenerate',
            name: '/campaigns/partials/CampaignGenerate',
            component: () => import('E:/Projects/PostMaker/postmaker/src/pages/campaigns/partials/CampaignGenerate.vue'),
            /* no children */
          },
          {
            path: 'CampaignInspiration',
            name: '/campaigns/partials/CampaignInspiration',
            component: () => import('E:/Projects/PostMaker/postmaker/src/pages/campaigns/partials/CampaignInspiration.vue'),
            /* no children */
          },
          {
            path: 'CampaignSchedule',
            name: '/campaigns/partials/CampaignSchedule',
            component: () => import('E:/Projects/PostMaker/postmaker/src/pages/campaigns/partials/CampaignSchedule.vue'),
            /* no children */
          },
          {
            path: 'CampaignVariationEdit',
            name: '/campaigns/partials/CampaignVariationEdit',
            component: () => import('E:/Projects/PostMaker/postmaker/src/pages/campaigns/partials/CampaignVariationEdit.vue'),
            /* no children */
          },
          {
            path: 'CampaignVariationList',
            name: '/campaigns/partials/CampaignVariationList',
            component: () => import('E:/Projects/PostMaker/postmaker/src/pages/campaigns/partials/CampaignVariationList.vue'),
            /* no children */
          },
          {
            path: 'CampaignVariationsList',
            name: '/campaigns/partials/CampaignVariationsList',
            component: () => import('E:/Projects/PostMaker/postmaker/src/pages/campaigns/partials/CampaignVariationsList.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/login',
    /* internal name: '/login' */
    /* no component */
    children: [
      {
        path: '',
        name: '/login/',
        component: () => import('E:/Projects/PostMaker/postmaker/src/pages/login/index.vue'),
        /* no children */
        meta: {
          "layout": "none",
          "anonymous": true
        }
      }
    ],
  },
  {
    path: '/logout',
    /* internal name: '/logout' */
    /* no component */
    children: [
      {
        path: '',
        name: '/logout/',
        component: () => import('E:/Projects/PostMaker/postmaker/src/pages/logout/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/posts',
    /* internal name: '/posts' */
    /* no component */
    children: [
      {
        path: '',
        name: '/posts/',
        component: () => import('E:/Projects/PostMaker/postmaker/src/pages/posts/index.vue'),
        /* no children */
      },
      {
        path: 'partials',
        /* internal name: '/posts/partials' */
        /* no component */
        children: [
          {
            path: 'EmptyPostList',
            name: '/posts/partials/EmptyPostList',
            component: () => import('E:/Projects/PostMaker/postmaker/src/pages/posts/partials/EmptyPostList.vue'),
            /* no children */
          },
          {
            path: 'PostListItem',
            name: '/posts/partials/PostListItem',
            component: () => import('E:/Projects/PostMaker/postmaker/src/pages/posts/partials/PostListItem.vue'),
            /* no children */
          },
          {
            path: 'PostsList',
            name: '/posts/partials/PostsList',
            component: () => import('E:/Projects/PostMaker/postmaker/src/pages/posts/partials/PostsList.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/profile',
    /* internal name: '/profile' */
    /* no component */
    children: [
      {
        path: '',
        name: '/profile/',
        component: () => import('E:/Projects/PostMaker/postmaker/src/pages/profile/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/team',
    /* internal name: '/team' */
    /* no component */
    children: [
      {
        path: '',
        name: '/team/',
        component: () => import('E:/Projects/PostMaker/postmaker/src/pages/team/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/variables',
    /* internal name: '/variables' */
    /* no component */
    children: [
      {
        path: '',
        name: '/variables/',
        component: () => import('E:/Projects/PostMaker/postmaker/src/pages/variables/index.vue'),
        /* no children */
      },
      {
        path: ':variableId',
        name: '/variables/[variableId]',
        component: () => import('E:/Projects/PostMaker/postmaker/src/pages/variables/[variableId].vue'),
        /* no children */
      }
    ],
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

