import { initializeAnalytics } from 'firebase/analytics'

import { initializeApp } from 'firebase/app'
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'
import { collection, getFirestore, initializeFirestore, query, where } from 'firebase/firestore'

import { configuration } from './config'

declare global {
  /* eslint-disable no-var, vars-on-top */
  var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean
}

export const firebaseApp = initializeApp(configuration)

initializeFirestore(firebaseApp, {
  ignoreUndefinedProperties: true,
})

initializeAnalytics(firebaseApp)

if (location.hostname === 'localhost') {
  globalThis.FIREBASE_APPCHECK_DEBUG_TOKEN = true
}

initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider('6LdnPckpAAAAAADqOX4e0moyWO2ASmrTQUF2Jx3U'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true,
})

const db = getFirestore(firebaseApp)

// connectAuthEmulator(auth, 'http://localhost:9099')
// connectFirestoreEmulator(db, 'localhost', 8081)

export const userRef = collection(db, 'users')
export const brandsRef = collection(db, 'clients')

export function accountBrandsRef(accountId: string) {
  return query(collection(db, 'clients'), where('owner', '==', accountId))
}

export function accountCampaignsRef(accountId: string) {
  return query(collection(db, 'campaigns'), where('owner', '==', accountId))
}

export const campaignsRef = collection(db, 'campaigns')

export function accountVariationsRef(accountId: string) {
  return query(collection(db, 'variations'), where('owner', '==', accountId))
}

export const variationsRef = collection(db, 'variations')

export function accountVariablesRef(accountId: string) {
  return query(collection(db, 'variables'), where('owner', '==', accountId))
}

export const variablesRef = collection(db, 'variables')
export const accountsRef = collection(db, 'accounts')
export const ideasRef = collection(db, 'ideas')

// export {
//   configuration,
//   db,
//   auth,
//   appCheck,
//   analytics,
// }
